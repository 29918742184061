
   
    .card{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 19vw;
        height: 50vh;
       
        box-shadow: 0 0 2px;
      
   
    
        .image{
            
            img{
            
                width: 19.2vw;
                height: 10rem;
            }

        }
        .text{
            background-color: #2f2a3a;
            padding: 10px;
            margin-top: -4px;
            color: #ffff;
                font-size: 20px;
        
        }
        .btn{
            width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #2f2a3a;
    flex-direction: column;
    
    button{
        padding: 3px;
        width: 100%;
        font-size: 17px;
        color: #ffff;
        cursor: pointer;
       border: none;
        background: rgb(99, 81, 204);
        height: 100%;
    margin: 3px;
    transition: .3s ease;
    a{
        text-decoration: none;
        color:#fff
    }
    }
    button:hover{
        box-shadow: 1px 1px 2px 1px;
     
    }
        }
        
    }

    @media screen and (max-width:480px){
        .container{
            .card{
                width: 57vw;
                height: 47vh;
                margin-bottom: 40px;
                .image{
                    img{
                    width: 57vw;
                    height: 11rem;

                }

                }
                .btn{
                    button{
                        height: 42px;
                    }

                }
                .text{
                    width: 57vw;
                   

                }
            }
        }
    }
    @media screen and (max-width:480px){
        .container{
            .card{
                .text{
                    font-size: 18px;
                    height: 58px;
                }
                .btn{
                    height: 31%;
                }
                .image{
                    img{
                        height: 9rem;
                    }
                }
                
            }
        }
    }
    @media screen and (max-width:426px){
        .container{
            .card{
                height: 39vh;
                width: 62vw;
                .text{
                    width: 62vw;
                }
                .btn{
                    width: 62vw;
                }
                .image{
                    width: 62vw;
                    img{

                        width: 62vw;
                    }
                }
              
            }
        }
    }
